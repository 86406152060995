import axios from "../../../axiosInstance"
import api from './api';

import {
	AddProjectSuccessResponse,
	AllProjectsSuccessResponse,
	DeleteProjectSuccessResponse,
	OneProjectSuccessResponse,
	PatchProjectSuccessResponse,
	ProjectsSuccessResponse,
	SearchProjectsSuccessResponse,
} from './responses/projects/projects.response';

import { GoogleUserInfoSuccessResponse } from './responses/user/user.response';
import {
	GoogleAuthSuccessResponse,
	SessionSuccessResponse,
} from './responses/auth/auth.response';
import { ProjectsFilters } from '../models/projects/project-filters.model';
import {
	CommentSuccessResponse,
	DeleteCommentSuccessResponse,
	PostCommentSuccessResponse,
} from './responses/projects/project-comment.response';
import { AddProjectDto } from './dto/project/add-project.dto';
import { AddProjectCommentDto } from './dto/project/add-comment.dto';
import { EditProfileInfoDto } from './dto/profile/edit-profile.dto';
import {
	DeleteUpvoteSuccessResponse,
	PostUpvoteSuccessResponse,
	UserUpvotesSuccessResponse,
} from './responses/projects/project-upvote.response';
import {
	ChartDataSuccessResponse,
	ProjectsStatusSuccessResponse,
	TotalGrantSuccessResponse,
	TotalUserSuccessResponse,
	UsersDataSuccessResponse,
} from './responses/admin/dashboard.responses';
import { GetPresignedUrlSuccessResponse } from './responses/image/images.response';
import { EditProjectDto } from './dto/project/edit-project.dto';
import { toast } from 'react-toastify';

import axiosInstance from "../../../axiosInstance";
import { ChallengeResponse } from "./responses/challenges/challenge.response";

import verifyWalletInstallQfn from "./qfn/verify-wallet-install.qfn";
import { ActivityResponse } from "./responses/user-activity/user-activity.response";
export type UserResponse = {
	user: {
		address: string;
		username?: string;
		bio?: string;
		twitterUrl?: string;
		telegramUrl?: string;
		linkedinUrl?: string;
	};
};

const QueryApi = {
	// POST
	verifyWallet: verifyWalletInstallQfn,

	images: {
		getPresignedUrl: async function (
			filename: string
		): Promise<GetPresignedUrlSuccessResponse> {
			const res = await axios<GetPresignedUrlSuccessResponse>({
				method: 'GET',
				url: api.images.getPresignedUrl.replace(/:filename/, filename),
			});

			return res.data;
		},

		getMultiplePresignedUrls: async function (
			filenames: string[]
		): Promise<GetPresignedUrlSuccessResponse[]> {
			const str = JSON.stringify(filenames);
			const encoded = encodeURIComponent(str);

			const res = await axios<GetPresignedUrlSuccessResponse[]>({
				method: 'GET',
				url: api.images.getMultiplePresignedUrls.replace(/:filenames/, encoded),
			});

			return res.data;
		},

		postImage: async function (presignedUrl: string, file: File): Promise<any> {
			const res = await axios({
				method: 'PUT',
				url: presignedUrl,
				data: file,
				headers: {
					'Content-Type': file.type,
				},
			});

			return res.data;
		},

		rollBackImage: async function (presignedUrl: string): Promise<any> {
			const res = await axios({
				method: 'DELETE',
				url: presignedUrl,
			});

			return res.data;
		},
	},
	userInvitations: {
		getUserInvitations: async function (userId: string): Promise<any> {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}
			const res = await axios.get(api.userInvitations.getUserInvitations(userId), {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return res.data;
		},
	},
	twitterPosts: {
		getUserPosts: async function (page: number, itemsPerPage: number): Promise<any> {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}
			const res = await axios.get(api.twitterPosts.getUserPosts(page, itemsPerPage), {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			return res.data;
		},
	},
	challenges: {
		// Function to set the button status to 'started'
		async setButtonStarted(challengeId: string, action: string): Promise<void> {
			try {
				const token = localStorage.getItem('bearerToken');
				const url = api.challenges.setButtonStarted(challengeId, action);
				await axios.post(url, null, {
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				});
				console.log(`Challenge status set to: ${action}`);
			} catch (error) {
				console.error('Error setting button to started:', error);
				throw error; // Ensure the error is handled by the calling function
			}
		},

		// Function to get the current button status for a specific challenge
		async getButtonStatuses(): Promise<Array<{ challengeId: string, action: string }>> {
			try {
				const token = localStorage.getItem('bearerToken');
				const url = api.challenges.getButtonStatus;
				const response = await axios.get(url, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});

				return response.data; // Response: [{ challengeId: "id", action: "started/fail", userId: "userId" }]
			} catch (error) {
				console.error('Error fetching challenge statuses:', error);
				throw error;
			}
		},
		async getNextClaimTime(): Promise<{ nextClaimTime: string }> {
			try {
				const token = localStorage.getItem('bearerToken');
				const url = api.challenges.getNextClaimTime
				const response = await axios.get(url, {
					headers: {
						Authorization: `Bearer ${token}`
					}
				});

				return response.data; // Expected response: { nextClaimTime: "2024-08-20T06:51:45Z" }
			} catch (error) {
				console.error('Error fetching next claim time:', error);
				throw error; // Ensure the error is handled by the calling function
			}
		},
		subscribeEmail: async (email: string, challengeId: string): Promise<any> => {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}
			const url = api.challenges.subscribeEmail(email, challengeId);
			try {
				const response = await axios.get(url, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				return response.data;
			} catch (error) {
				console.error('Error subscribing email:', error);
				throw error;
			}
		},
		requestReferralPoints: async function (referredUserId: string, referralCode: string): Promise<any> {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}
			const url = api.challenges.requestReferralPoints
				.replace(':referredUserId', referredUserId)
				.replace(':referralCode', referralCode);
			console.log('Final URL:', url); // Log the final URL to ensure correctness

			const response = await axios.post(url, {}, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		},
		connectTelegram: async (callerId: string) => {
			const response = await axios.get(`${api.challenges.connectTelegram}?callerId=${callerId}`);
			return response.data;
		},
		claimBonusPoint: async function (): Promise<any> {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}
			const response = await axios.get(api.challenges.claimBonusPoint, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		},
		getQuests: async function (): Promise<any> {
			const res = await axios<any>({
				method: 'GET',
				url: api.challenges.getQuests,
			});

			return res.data;
		},
		getChallengeById: async function (questId: string): Promise<ChallengeResponse> {
			const res = await axios.get<ChallengeResponse>(api.challenges.getChallengeById(questId));
			return res.data;
		},
		finalizeChallenge: async (questId: string): Promise<any> => {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}

			try {
				const response = await axiosInstance.post(api.challenges.finalizeChallenge(questId), null, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				return response.data;
			} catch (error) {
				console.error('Error finalizing challenge:', error);
				throw error;
			}
		},

		fetchCompletedChallenges: async (): Promise<string[]> => {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}

			console.log('Bearer token:', token);

			try {
				const response = await axiosInstance.get('/api/quests/completed', {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});


				if (typeof response.data === 'string') {
					console.error('Expected JSON but received HTML:', response.data);
					throw new Error('Unexpected HTML response');
				}

				return response.data;
			} catch (error) {
				console.error('Error fetching completed challenges:', error);
				throw error;
			}
		},
		fetchInreviewChallenges: async (): Promise<any> => {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}
			const response = await axiosInstance.get(api.challenges.fetchInreviewChallenges, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		},

		deleteQuest: async function (id: string): Promise<any> {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}
			const res = await axios<any>({
				method: 'POST',
				url: `${api.challenges.deleteQuest}`,
				headers: {
					Authorization: `Bearer ${token}`,
				},
				data: {
					id: id,
				},
			});
			return res.data;
		},
		postQuest: async function (questData: any): Promise<any> {
			try {
				const res = await axios({
					method: 'POST',
					url: api.challenges.postQuest,
					data: questData,
					withCredentials: true,
					headers: {
						'Content-Type': 'application/json',
					},
				});

				return res.data;
			} catch (error) {
				toast.error('Error creating a quest challenge');
				throw error;
			}
		},
		createQuest: async function (questData: any): Promise<any> {
			try {
				const res = await axios({
					method: 'POST',
					url: api.challenges.postQuest,
					data: questData,
					withCredentials: true,
					headers: {
						'Content-Type': 'application/json',
					},
				});

				return res.data;
			} catch (error) {
				toast.error('Error creating a quest challenge');
				throw error;
			}
		},
		verifyChallengeWithDiscord: async (userId: string, challengeId: string, discordId?: string): Promise<any> => {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}

			const response = await axios.post(api.challenges.verifyChallenge, null, {
				params: {
					userId,
					challengeId,
					discordId
				},
				headers: {
					Authorization: `Bearer ${token}`
				}
			});

			return response.data;
		},
		getDiscordAuthUrl: async (userId: string) => {
			try {
				const response = await axios.get(api.challenges.getDiscordAuthUrl(userId));
				return response.data;
			} catch (error) {
				console.error('Failed to get Discord auth URL', error);
				throw new Error('Failed to get Discord auth URL');
			}
		},
		verifyChallenge: async (userId: string, challengeId: string, telegramId?: string, discordId?: string) => {
			const token = localStorage.getItem('bearerToken');
			const response = await axios.post(api.challenges.verifyChallenge, null, {
				params: {
					userId,
					challengeId,
					telegramId,
					discordId
				},
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			return response.data;
		},

		requestQuestVerification: async (challengeId: string, data: { post_link: string }) => {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}

			const requestData = { challenge_id: challengeId, ...data };

			const response = await axiosInstance.post('/api/quests/request/verify', requestData, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return response.data;
		},
	}, leaderboard: {
		async getSummary(): Promise<{ totalUsers: number, totalQuestsCompleted: number, totalPoints: number }> {
			try {
				const token = localStorage.getItem('bearerToken');
				if (!token) {
					throw new Error('No bearer token found in local storage');
				}

				const response = await axios.get(api.leaderboard.getSummary, {
					headers: {
						Authorization: `Bearer ${token}`,
					}
				});

				return response.data;
			} catch (error) {
				console.error('Error fetching leaderboard summary:', error);
				throw error; // Ensure the error is handled by the calling function
			}
		},

		async getLeaderboardTopUsers(page: number, limit: number): Promise<any> {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}

			const response = await axios.get(api.user.getLeaderboardTopUsers(page, limit), {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		},

		// Function to get the current user's leaderboard information
		async getLeaderboardMe(): Promise<any> {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}

			const response = await axios.get(api.user.getLeaderboardMe, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			console.log('Leaderboard me response', response.data);

			return response.data;
		},

		async getSummaryV2(params: {
			startDate?: string | null,
			endDate?: string | null,
			filterType?: 'weekly' | 'monthly' | 'yearly' | 'custom' | null
		}): Promise<{
			totalUsers: number,
			totalReferrals: number,
			totalPoints: number,
			totalQuestsCompleted: number,
			totalWallets: number,
			totalEmails: number,
			totalDiscordAccounts: number,
			totalTelegramAccounts: number
		}> {
			try {
				const token = localStorage.getItem('bearerToken');
				if (!token) {
					throw new Error('No bearer token found in local storage');
				}

				const response = await axios.get(api.leaderboard.getSummaryV2, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params: {
						startDate: params.startDate || null, // Pass null if no value
						endDate: params.endDate || null, // Pass null if no value
						filterType: params.filterType || null // Pass null if no value
					}
				});

				return response.data;
			} catch (error) {
				console.error('Error fetching elaborated leaderboard summary:', error);
				throw error; // Ensure the error is handled by the calling function
			}
		},
		async getTop10V2({
			page,
			limit,
			sortBy,
			searchTerm = '',
			filterType = '',
			startDate = '',
			endDate = '',
			is_degen = false
		}: {
			page: number;
			limit: number;
			sortBy: string;
			searchTerm?: string;
			filterType?: string;
			startDate?: string;
			endDate?: string;
			is_degen?: boolean;
		}): Promise<Array<{
			claimedInvites: number,
			bonusPoints: number,
			accountCreated: string,
			totalPoints: number,
			lastLoggedIn: string,
			username: string
		}>> {
			try {
				const token = localStorage.getItem('bearerToken');
				if (!token) {
					throw new Error('No bearer token found in local storage');
				}

				const params = {
					page,
					limit,
					sortBy,
					searchTerm,
					filterType,
					startDate,
					endDate,
					is_degen
				};

				const response = await axios.get(api.leaderboard.getTop10V2, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
					params
				});

				return response.data;
			} catch (error) {
				console.error('Error fetching top 10 leaderboard:', error);
				throw error; // Ensure the error is handled by the calling function
			}
		},

		async getTop10(): Promise<Array<{
			claimedInvites: number,
			bonusPoints: number,
			accountCreated: string,
			totalPoints: number,
			lastLoggedIn: string,
			username: string
		}>> {
			try {
				const token = localStorage.getItem('bearerToken');
				if (!token) {
					throw new Error('No bearer token found in local storage');
				}

				const response = await axios.get(api.leaderboard.getTop10, {
					headers: {
						Authorization: `Bearer ${token}`,
					}
				});

				return response.data;
			} catch (error) {
				console.error('Error fetching top 10 leaderboard:', error);
				throw error; // Ensure the error is handled by the calling function
			}
		},
	},

	projects: {
		getAll: async function (
			filters: ProjectsFilters,
			page: number = 0,
			limit: number = 5
		): Promise<AllProjectsSuccessResponse> {
			const { deploymentType, sortType } = filters;

			const params = new URLSearchParams();
			if (deploymentType) params.append('projectDeployment', deploymentType);
			if (sortType) params.append('sort', sortType);
			params.append('page', page.toString());
			params.append('limit', limit.toString());

			const res = await axios<AllProjectsSuccessResponse>({
				method: 'GET',
				url: api.projects.getAll.replace(/:params/, params.toString()),
			});

			return res.data;
		},
		getOne: async function (projectId: string | number): Promise<OneProjectSuccessResponse> {
			const res = await axios<OneProjectSuccessResponse>({
				method: 'GET',
				url: api.projects.getOne.replace(/:id/, projectId as string),
			});

			return res.data;
		},

		searchProjects: async function (
			searchTerm: string
		): Promise<SearchProjectsSuccessResponse> {
			const res = await axios<SearchProjectsSuccessResponse>({
				method: 'GET',
				url: api.projects.search.replace(/:name/, searchTerm), // Include the searchTerm as a query parameter
			});
			return res.data;
		},

		addUpvote: async function (
			userId: string,
			projectId: string | number
		): Promise<PostUpvoteSuccessResponse> {
			const res = await axios<PostUpvoteSuccessResponse>({
				method: 'POST',
				url: api.projects.addUpvote,
				data: {
					uid: userId,
					projectId: projectId,
				},
				withCredentials: true,
			});

			return res.data;
		},

		delUpvote: async function (
			userId: string,
			projectId: string | number
		): Promise<DeleteUpvoteSuccessResponse> {
			const res = await axios<DeleteUpvoteSuccessResponse>({
				method: 'DELETE',
				url: api.projects.removeUpvote,
				data: {
					uid: userId,
					projectId,
				},
				withCredentials: true,
			});

			return res.data;
		},

		getComments: async function (projectId: string | number): Promise<CommentSuccessResponse> {
			const res = await axios<CommentSuccessResponse>({
				method: 'GET',
				url: api.comments.get(projectId as string),
			});

			return res.data;
		},

		addComment: async function (
			commentDto: AddProjectCommentDto
		): Promise<PostCommentSuccessResponse> {
			const finalDto = { ...commentDto };
			// @ts-expect-error - We don't want to send this to the server
			delete finalDto.randomId;

			const res = await axios<PostCommentSuccessResponse>({
				method: 'POST',
				url: api.comments.add,
				data: finalDto,
				withCredentials: true,
			});

			return res.data;
		},
		deleteComment: async function (commentId: number): Promise<DeleteCommentSuccessResponse> {
			const res = await axios<DeleteCommentSuccessResponse>({
				method: 'DELETE',
				url: api.comments.deleteComment, // Assuming this resolves to https://{{host}}/comment/
				data: {
					id: commentId, // Pass the commentId in the request body
				},
				withCredentials: true,
			});

			return res.data;
		},
		add: async function (
			projectFormData: AddProjectDto | EditProjectDto
		): Promise<AddProjectSuccessResponse> {
			const res = await axios<AddProjectSuccessResponse>({
				method: 'POST',
				url: api.projects.add,
				data: projectFormData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			});

			return res.data;
		},
		editProject: async function (
			formData: EditProjectDto
		): Promise<PatchProjectSuccessResponse> {
			const res = await axios<PatchProjectSuccessResponse>({
				method: 'PATCH',
				url: api.projects.editProject,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			});

			return res.data;
		},

		delete: async function (projectId: string | number): Promise<DeleteProjectSuccessResponse> {
			const res = await axios<DeleteProjectSuccessResponse>({
				method: 'DELETE',
				url: api.projects.delete,
				data: {
					id: projectId,
				},
				withCredentials: true,
			});

			return res.data;
		},
	},

	auth: {
		googleSignin: async function (tokenId: string): Promise<GoogleAuthSuccessResponse> {
			const res = await axios<GoogleAuthSuccessResponse>({
				method: 'POST',
				url: api.auth.googleSignIn,
				data: {
					idToken: tokenId,
				},
				withCredentials: true,
			});

			return res.data;
		},
		twitterSignin: async function (token: string): Promise<any> {
			console.log('Bearer token:', token);

			const res = await axios<any>({
				method: 'POST',
				url: api.auth.twitterSigninPost,
				data: {
					idToken: token,
				},
				withCredentials: true,
			});

			return res.data;
		},
		twitterSigninPost: async function ({ idToken }: { idToken: string }): Promise<any> {
			const res = await axios<any>({
				method: 'POST',
				url: api.auth.twitterSigninPost,
				data: { idToken },
				withCredentials: true,
			});
			if (res.data?.user?.is_admin) {
				localStorage.removeItem('isAdmin');
				localStorage.setItem('isAdmin', 'true');
				toast.info('You have admin privileges');
			} else {
				localStorage.removeItem('isAdmin');
				localStorage.setItem('isAdmin', 'false');
			}
			return res.data;
		},
		twitterLogOut: async function (): Promise<any> {
			try {
				const res = await axios<any>({
					method: 'POST',
					url: api.auth.twitterLogOut,
				});

				return res.data;
			} catch (error) {
				toast.error('Error trying to logout');
				throw error;
			}
		},
		logout: async function (): Promise<any> {
			const res = await axios({
				method: 'POST',
				url: api.auth.logout,
				withCredentials: true,
			});

			return res.data;
		},

		session: async function (): Promise<SessionSuccessResponse> {
			const res = await axios<SessionSuccessResponse>({
				method: 'GET',
				url: api.auth.session,
				withCredentials: true,
			});

			return res.data;
		},
	},

	user: {

		getUserProfile: async (username: string) => {
			try {
				const response = await axios.get(api.user.getUserProfile(username));
				return response.data;
			} catch (error) {
				console.error('Error fetching user profile:', error);
				throw error;
			}
		},
		googleGetInfo: async function (userId: string): Promise<GoogleUserInfoSuccessResponse> {
			const res = await axios<GoogleUserInfoSuccessResponse>({
				method: 'GET',
				url: api.user.googleGetInfo.replace(/:id/, userId),
			});

			return res.data;
		},
		getInfo: async function (address: string): Promise<UserResponse> {
			const res = await axios<UserResponse>({
				method: 'GET',
				url: api.user.getInfo.replace(/:address/, address),
			});

			return res.data;
		},
		getActivity: async function (): Promise<ActivityResponse> {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}
			const res = await axios<ActivityResponse>({
				method: 'GET',
				url: api.user.getActivity,
				headers: {
					Authorization: `Bearer ${token}`, // Replace with actual token
				},
			});

			return res.data;
		},
		getUpvotedProjects: async function (
			userId: string,
			page: number = 0,
			limit: number = 1000
		): Promise<UserUpvotesSuccessResponse> {
			const params = new URLSearchParams();
			params.append('page', page.toString());
			params.append('limit', limit.toString());

			const res = await axios<UserUpvotesSuccessResponse>({
				method: 'GET',
				url: api.user.getUpvotes
					.replace(/:userId/, userId)
					.replace(/:params/, params.toString()),
			});

			return res.data;
		},

		getUpvotedProject: async function (
			userId: string
			/*,projectId: number */
		): Promise<UserUpvotesSuccessResponse> {
			const params = new URLSearchParams();
			params.append('page', '0');
			params.append('limit', '1000');

			const res = await axios<UserUpvotesSuccessResponse>({
				method: 'GET',
				url: api.user.getUpvotes
					.replace(/:userId/, userId)
					.replace(/:params/, params.toString()),
			});

			return res.data;
		},

		async addOrUpdateFlag(flag: string): Promise<{ success: boolean; message: string }> {
			try {
				const token = localStorage.getItem('bearerToken');
				if (!token) {
					throw new Error('No bearer token found in local storage');
				}

				const response = await axios.post(api.user.addOrUpdateFlag(flag), {}, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				return response.data;
			} catch (error) {
				console.error('Error updating user flag:', error);
				throw error;
			}
		},
		async deleteFlag(): Promise<{ success: boolean; message: string }> {
			try {
				const token = localStorage.getItem('bearerToken');
				if (!token) {
					throw new Error('No bearer token found in local storage');
				}

				const response = await axios.post(api.user.deleteFlag(), {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});
				return response.data;
			} catch (error) {
				console.error('Error deleting user flag:', error);
				throw error;
			}
		},

		async addOrUpdateInterests(tags: string[]): Promise<{ success: boolean; message: string }> {
			try {
				const token = localStorage.getItem('bearerToken');
				if (!token) {
					throw new Error('No bearer token found in local storage');
				}

				const response = await axios.post(api.user.addOrUpdateInterests, { tags }, {
					headers: {
						Authorization: `Bearer ${token}`,
						'Content-Type': 'application/json',
					},
				});

				return response.data;
			} catch (error) {
				console.error('Error updating user interests:', error);
				throw error;
			}
		},

		async getTop11(): Promise<Array<{
			selectedFlag: string;
			name: string;
			twitter_profile_url: string;
			interests: string[];
			no_of_tags: string;
			points: number;
		}>> {
			try {
				const token = localStorage.getItem('bearerToken');
				if (!token) {
					throw new Error('No bearer token found in local storage');
				}

				const response = await axios.get(api.user.getTop11, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				return response.data;
			} catch (error) {
				console.error('Error fetching top 11 users:', error);
				throw error;
			}
		}


	},
	ambassador: {
		submitEmbedCode: async function (embedCode: string): Promise<any> {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}

			const res = await axios<any>({
				method: 'POST',
				url: api.ambassador.submitEmbedCode,
				data: { embedCode },
				headers: {
					Authorization: `Bearer ${token}`,
					'Content-Type': 'application/json',
				},
			});
			return res.data;
		},
		getAllEmbedCodes: async function (page: number, itemsPerPage: number): Promise<any> {
			const res = await axios.get(api.ambassador.getAllEmbedCodes(page, itemsPerPage));
			return res.data;
		}
	},
	profile: {
		googleGetInfo: async function (userId: string): Promise<GoogleUserInfoSuccessResponse> {
			const res = await axios<GoogleUserInfoSuccessResponse>({
				method: 'GET',
				url: api.user.googleGetInfo.replace(/:id/, userId),
			});

			return res.data;
		},

		getUpvotedProjects: async function (userId: string): Promise<ProjectsSuccessResponse> {
			const res = await axios<ProjectsSuccessResponse>({
				method: 'GET',
				url: api.profile.upvotedProjects.replace(/:userId/, userId),
			});

			return res.data;
		},

		getSubmittedProjects: async function (userId: string): Promise<ProjectsSuccessResponse> {
			const res = await axios<ProjectsSuccessResponse>({
				method: 'GET',
				url: api.profile.submittedProjects.replace(/:userId/, userId),
			});

			return res.data;
		},

		editProfile: async function (data: EditProfileInfoDto): Promise<EditProfileInfoDto> {
			const res = await axios<EditProfileInfoDto>({
				method: 'PATCH',
				url: api.profile.editProfile,
				data: data,
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				withCredentials: true,
			});

			return res.data;
		},
	},
	// to fetch total submissions
	users: {
		getUserById: async (userId: string): Promise<any> => {
			const res = await axios.get(api.user.getUserById(userId)); // Pass the userId to construct the URL
			return res.data;
		},
	},
	admin: {
		setDegenStatus: async ({ userId, isDegen }: { userId: string, isDegen: boolean }): Promise<void> => {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}

			const response = await axios.post(api.admin.setDegenStatus, { userId, isDegen }, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		},

		setPostPoints: async (userId: string, points: number): Promise<void> => {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}

			const response = await axios.post(api.admin.updateAdminPoints, { userId, points }, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			return response.data;
		},
		getUnresolvedQuests: async (): Promise<{ user_id: string; challenge_id: string; fulfilled: boolean; link: string }[]> => {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}

			const response = await axiosInstance.get('/api/admin/quests/unresolved', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (typeof response.data === 'string') {
				console.error('Expected JSON but received HTML:', response.data);
				throw new Error('Unexpected HTML response');
			}

			return response.data;
		},
		updateUserPoints: async (userId: string, points: number): Promise<void> => {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}

			// Create the body of the request
			const requestBody = {
				userId,
				points,
			};

			// Make the POST request to update the user points
			const response = await axiosInstance.post('/api/admin/user/points/update', requestBody, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});

			if (typeof response.data === 'string') {
				console.error('Expected JSON but received HTML:', response.data);
				throw new Error('Unexpected HTML response');
			}

			return response.data;
		},
		disqualifyUser: async (userId: string): Promise<void> => {
			try {
				const token = localStorage.getItem('bearerToken'); // Assuming the token is stored in localStorage
				if (!token) {
					throw new Error('No bearer token found in local storage');
				}

				const response = await axiosInstance.post(api.admin.disqualifyUser(userId), null, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				return response.data; // Optionally handle any returned data
			} catch (error) {
				console.error('Failed to disqualify user:', error);
				throw error;
			}
		},
		deleteUserPost: async (postId: string): Promise<void> => {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}

			// Make the DELETE request with postId as query parameter
			const response = await axiosInstance.post(
				`${api.admin.deleteUserPost}?postId=${postId}`, // PostId in the URL as query parameter
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);

			return response.data;
		},
		searchUsers: async (username: string, limit: number = 20): Promise<any> => {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}

			const apiUrl = `/api/search/users/${username}/${limit}?username=${username}`;

			try {
				const response = await axiosInstance.get(apiUrl, {
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				if (typeof response.data === 'string') {
					console.error('Expected JSON but received HTML:', response.data);
					throw new Error('Unexpected HTML response');
				}

				return response.data;
			} catch (error) {
				console.error('Error fetching users:', error);
				throw error;
			}
		},
		// verifyUsers: async function (
		// 	userId: string,
		// 	challengeId: string
		// ): Promise<VerificationSuccessResponse> {
		// 	try {
		// 		const res = await axios<VerificationSuccessResponse>({
		// 			method: 'POST',
		// 			url: api.admin.forceVerify,
		// 			data: {
		// 				userId: userId,
		// 				challengeId: challengeId,
		// 			},
		// 			withCredentials: true,
		// 		});

		// 		toast.success('User verified successfully');
		// 		return res.data;
		// 	} catch (error) {
		// 		toast.error('Force verification failed');
		// 		throw error;
		// 	}
		// },
		forceVerify: async (userId: string, challengeId: string) => {
			const token = localStorage.getItem('bearerToken');
			if (!token) {
				throw new Error('No bearer token found in local storage');
			}
			const response = await axiosInstance.post(
				api.challenges.forceVerify(userId, challengeId), // Use the api.challenges.forceVerify endpoint
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			return response.data;
		},
		getTotalUsers: async function (): Promise<TotalUserSuccessResponse> {
			const res = await axios({
				method: 'GET',
				url: api.admin.getTotalUsers,
				withCredentials: true,
			});

			return res.data;
		},

		getTotalGrants: async function (): Promise<TotalGrantSuccessResponse> {
			const res = await axios<TotalGrantSuccessResponse>({
				method: 'GET',
				url: api.admin.getTotalGrants,
				withCredentials: true,
			});

			return res.data;
		},

		getProjectsStatus: async function (): Promise<ProjectsStatusSuccessResponse> {
			const res = await axios({
				method: 'GET',
				url: api.admin.getProjectStatus,
				withCredentials: true,
			});

			return res.data;
		},

		getUsersData: async function (): Promise<UsersDataSuccessResponse> {
			const res = await axios<UsersDataSuccessResponse>({
				method: 'GET',
				url: api.admin.getUsersData,
				withCredentials: true,
			});

			return res.data;
		},

		getGrantChartData: async function (
			time: 'yearly' | 'monthly' | 'weekly'
		): Promise<ChartDataSuccessResponse> {
			const res = await axios<ChartDataSuccessResponse>({
				method: 'GET',
				url: api.admin.getGrantChartData.replace(/:time/, time),
				withCredentials: true,
			});

			return res.data;
		},
	},
};

export default QueryApi;
